@import "general/colors";
@import "general/buttons";
@import "general/typography";
//* --------------banner-----------------
.banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.banner__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}

.banner__title {
    @include h1--image;
    color: $white;
}

.banner__link {
    color: $white;
    font-weight: 900;
    @include h4--button;
    letter-spacing: 0.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &>.bx {
        color: $white;
    }
}

.banner__link:hover {
    color: $secondary;
    &>.bx {
        color: $secondary;
    }
}

.nav__search-bar {
    align-self: flex-start;
    position: static;
    &>span {
        color: $extra;
    }
}

@media screen and (max-width: 574px) {
    .banner {
        height: 327px;
    }
    .banner__link {
        font-size: 16px;
    }
}

//* ============= second part =============
.banner {
    height: calc(100vh - 92px);
}

.banner--down-button {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, 60%);
    transform: translate(-50%, 60%);
    background-color: white;
    width: 111px;
    height: 91px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 3em;
    padding-bottom: 0.7em;
    cursor: pointer;
}

@media screen and (max-width: 1440px) {
    .banner {
        height: calc(100vh - 72px);
    }
}

@media screen and (max-width: 1620px) {
    .banner {
        &__video {
            width: auto;
            height: auto;
        }
    }
}

@media screen and (max-width: 1275px) {
    .banner {
        height: calc(100vh - 75px);
    }
}

@media screen and (max-width: 1090px) {
    .banner__video {
        height: 100%;
    }
}

@media screen and (max-width: 574px) {
    .banner {
        height: 327px;
    }
    .banner--down-button {
        display: none;
    }
}

//* =================== standard banner =============
.banner__container {
    margin: 0 26.09%;
}

.banner__link {
    gap: 42px;
    &>.bx {
        font-size: 2em;
    }
}

@media screen and (max-width: 1919px) {
    .banner__container {
        margin: 0 18.21%;
    }
}

@media screen and (max-width: 1399px) {
    .banner__container {
        margin: 0 14.45%;
    }
    .banner__link {
        gap: 28.46px;
        &>.bx {
            font-size: 2em;
        }
    }
}

@media screen and (max-width: 767px) {
    .banner__container {
        margin: 0 4.87%;
    }
    .banner__title {
        font-size: 27.92px;
    }
    .banner__link {
        gap: 29.16px;
    }
}

@media screen and (max-width: 573px) {
    .banner__container {
        margin: 0 11.22%;
    }
    .banner__link {
        gap: 21.49px;
    }
}